<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="150px" :model="formLabelAlign">
        <el-form-item
          label="活动名称："
          prop="name"
          :rules="[{ required: true, message: '请输入活动名称' }]"
        >
          <el-input
            placeholder="请输入活动名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="申报部门："
          prop="department_id"
          :rules="[{ required: true, message: '请选择申报部门' }]"
        >
          <Bepartment
            class="form-inp"
            ref="Bepartment"
            v-model="formLabelAlign.department_id"
          />
        </el-form-item>

        <el-form-item
          label="活动人群："
          prop="add_type"
          :rules="[{ required: true, message: '请选择活动人群' }]"
        >
          <el-select
            v-model="formLabelAlign.add_type"
            placeholder="请选择活动人群："
            class="form-inp"
          >
            <el-option
              v-for="item in add_type_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          label="活动人数："
          prop="people_num"
          :rules="[{ required: true, message: '请输入活动人数' }]"
        >
          <el-input
            placeholder="请输入活动人数"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.people_num"
          ></el-input>
        </el-form-item> -->

        <el-form-item
          label="活动地点："
          prop="place_id"
          :rules="[{ required: true, message: '请选择地点' }]"
        >
          <el-select
            v-model="formLabelAlign.place_id"
            placeholder="请选择地点"
            class="form-inp"
          >
            <el-option
              v-for="item in place_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item
          label="活动人数："
          prop="people_num"
        >
          <el-input
            placeholder="请输入活动人数"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.people_num"
          ></el-input>
        </el-form-item>

           <div class="time-tys">
            <el-form-item
              label="开始时间："
              prop="begin_time"
              :rules="[{ required: true, message: '请选择开始时间' }]"
            >
              <div>
                <el-date-picker
                  class="time-inp"
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.begin_time"
                  type="date"
                  placeholder="开始时间"
                  @change="DateChange()"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <p>至</p>
            <el-form-item
              label-width="0px"
              label=""
              prop="end_time"
              :rules="[{ required: true, message: '请选择结束时间' }]"
            >
              <div>
                <el-date-picker
                  class="time-inp"
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.end_time"
                  type="date"
                  placeholder="结束时间"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </div>
        <el-form-item
          label="活动联系人："
          prop="contact"
          :rules="[{ required: true, message: '请输入联系人' }]"
        >
          <el-input
            placeholder="请输入联系人"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.contact"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="联系人电话："
          prop="contact_phone"
          :rules="[
            { required: true, message: '请输入联系人电话' },
            { type: 'number', message: '电话必须为数字值' },
          ]"
        >
          <el-input
            placeholder="请输入联系人电话"
            size="mini"
            class="form-inp"
            v-model.number="formLabelAlign.contact_phone"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="校内联系人："
          prop="response_uid"
          :rules="[{ required: true, message: '请选择校内联系人：' }]"
        >
        <el-select
          v-model="formLabelAlign.response_uid"
          filterable
          remote
          size="mini"
          reserve-keyword
          placeholder="请输入联系人姓名"
          :remote-method="getUserAndDept"
          :loading="loading">
          <el-option
            v-for="item in userList"
            :key="item.ding_userid"
            :label="item.nickname"
            :value="item.ding_userid">
            <span style="float: left">{{ item.nickname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.department }}</span>
          </el-option>
        </el-select>
       </el-form-item>
        <el-form-item
          label="是否有宣传材料："
          prop="has_materials"
          :rules="[{ required: true, message: '请选择状态' }]"
        >
          <el-select
            v-model="formLabelAlign.has_materials"
            placeholder="请选择当前状态："
            class="form-inp"
          >
            <el-option
              v-for="item in has_materials_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="formLabelAlign.has_materials == '是'"
          label="宣传材料内容"
          prop="materials_content"
          :rules="[{ required: true, message: '请选择宣传材料内容' }]"
        >
        <el-input
            type="textarea"
            placeholder="请选择宣传材料内容"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.materials_content"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="活动内容："
          prop="desc"
          :rules="[{ required: true, message: '请输入活动内容' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入活动内容"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.desc"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { activitysaveInfo, activitysave,getUserAndDepartment } from "../../assets/request/api";
import uploadingFile from "../../components/uploading/uploading_File.vue";
import Bepartment from "../../components/Bepartment";
export default {
  name: "Activities_redact",
  components: {
    Bepartment,
    uploadingFile,
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      loading:false,
      formLabelAlign: {
        name: "",
        people_num: 0,
        department_id: "",
        desc: "",
        contact: "",
        contact_phone: "",
        place_id:null,
        add_type:"",
        place: "",
        response_uid:"",
        has_materials:"否",
        materials_content:"",
        status: "",
        begin_time: "",
        end_time: "",
        attachment_List: [],
      },
      timeData: [],
      options: [],
      status_List: [],
      place_list:[],
      has_materials_list:[
        {'id':"是",'name':"是"},
        {'id':"否",'name':"否"}
      ],
      userList:[],
      add_type_list:[
        {
          'id':"学生",
          'name':"学生"
        },{
          'id':"教职工",
          'name':"教职工"
        },
      ],
      //统计周期结束时间的最低选择时间
      pickerOptions: {},
    };
  },
  methods: {
    getUserAndDept(query) {
        if (query !== '') {
          this.loading = true;
          getUserAndDepartment({name:query}).then((res)=>{
            this.loading = false;
            this.userList =res.data;
          })
        } else {
          this.userList = [];
        }
      },

    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.begin_time
          );
        },
      };
    },
    way_show(id) {
      return activitysaveInfo({ id: id }).then((res) => {
        let { formLabelAlign,userList } = this;
        this.options = res.data.enable_list;
        this.status_List = res.data.status;
        this.place_list =res.data.placeList;
        if (id) {
          this.type_name = "修改";
          this.id = id;
          let {
            name,
            people_num,
            department_id,
            desc,
            contact,
            contact_phone,
            place,
            place_id,
            add_type,
            response_uid,
            has_materials,
            materials_content,
            status,
            begin_time,
            end_time,
            attachment,
            responseUserList,
          } = res.data.info;

          this.$refs.Bepartment.bepartment_List = department_id;
          formLabelAlign.department_id = department_id;
          formLabelAlign.id = id;
          formLabelAlign.name = name;
          formLabelAlign.people_num = people_num;
          formLabelAlign.place_id =place_id;
          formLabelAlign.add_type =add_type;
          formLabelAlign.response_uid =response_uid;
          formLabelAlign.has_materials =has_materials;
          formLabelAlign.materials_content =materials_content;
          formLabelAlign.desc = desc;
          formLabelAlign.contact = contact;
          formLabelAlign.contact_phone = Number(contact_phone);
          formLabelAlign.place = place;
          formLabelAlign.status = status;
          formLabelAlign.begin_time = begin_time;
          formLabelAlign.end_time = end_time;
          this.timeData = [begin_time, end_time];
          this.userList = responseUserList
          this.DateChange(1);
          if (attachment) {
            formLabelAlign.attachment_List = attachment.split(",");
          }
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          formLabelAlign.attachment = formLabelAlign.attachment_List.join(",");
          delete formLabelAlign.attachment_List;
          activitysave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.time-tys {
  display: flex;
  p {
    margin: 0 5px;
    padding-top:7px
  }
  .time-inp {
    width: 160px;
  }
}
</style>