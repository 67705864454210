<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <div class="separate">
          <el-drawer title="选择部门" :visible.sync="drawer_show" direction="ltr">
            <BepartmentTree @on_controlid="on_controlid" />
          </el-drawer>
          <div class="tablemessage">
            <p class="describe">活动报备筛选</p>
            <div class="segmentation"></div>
            <div class="from_submit">
              <el-form :model="formLabelAlign" class="from">
                <el-form-item style="width: 340px;" class="BM">
                  <div class="justify">部 门：<span></span></div>
                  <el-input disabled @click.native="drawer_show = true" placeholder="请点击选择部门" size="mini" class="form-inp"
                    v-model="department_name"></el-input>
                </el-form-item>
                <el-form-item label="名称：" label-width="65px" style="width:310px">
                  <el-input placeholder="请输入名称" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
                </el-form-item>

                <el-form-item label="状态：" label-width="65px" style="width:310px">
                  <el-select v-model="formLabelAlign.status" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="是否启用：">
              <el-select
                v-model="formLabelAlign.is_enable"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in enable_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
                <el-form-item label="时间选择：" label-width="90px">
                  <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.bengin_time" type="date"
                    placeholder="开始日期" @change="DateChange()">
                  </el-date-picker>
                  至
                  <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time" type="date"
                    placeholder="结束日期" :picker-options="pickerOptions">
                  </el-date-picker>
                </el-form-item>
              </el-form>
              <div class="search_button">
                <div>
                  <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
                  <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个活动</span>
            <span class="span2">已选择 {{ more_List.length }} 个活动</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_pop_addplus()" icon="el-icon-plus">新建</el-button>
            <el-button @click="on_poprw_flow" icon="el-icon-tickets">创建任务</el-button>
            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="名称">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_particulars(scope.row.id)">{{ scope.row.name
                }}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="department" label="所属部门"></el-table-column>
            <el-table-column prop="response_uid" label="校内联系人"></el-table-column>
            <el-table-column prop="place" label="活动地点"></el-table-column>
            <el-table-column prop="begin_time" label="开始时间"></el-table-column>
            <el-table-column prop="end_time" label="结束时间"></el-table-column>
            <!-- <el-table-column
                    prop="status"
                    label="审批状态"
                  ></el-table-column> -->


            <!-- <el-table-column prop="address" label="任务完成">
                    <template slot-scope="scope">
                      <div class="schedule">{{ scope.row.finish_percent }}</div>
                    </template>
                  </el-table-column>

                  <el-table-column label="当前任务">
                    <template slot-scope="scope">
                      <div v-if="scope.row.instance">
                        <div
                          class="schedule"
                          v-for="(item, index) in scope.row.instance"
                          :key="index"
                        >
                          <el-tooltip placement="bottom" effect="light">
                            <div slot="content">
                              开始时间:{{ item.begin_time }}<br />结束时间:{{
                                item.end_time
                              }}
                            </div>
                            <p>{{ item.task_name }}</p>
                          </el-tooltip>
                        </div>
                      </div>
                      <div v-else>未创建任务</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="last_score"
                    label="扣分"
                  ></el-table-column> -->

            <!-- <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  @change="on_is_enable($event, scope.row.id)"
                  v-model="scope.row.is_enable"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column> -->

            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link v-if="scope.row.status == 0" class="list_sty" type="primary"
                  @click="on_pop_addplus(scope.row.id)">修改</el-link>
                <el-link v-if="scope.row.status != 0" class="list_sty" type="default" disabled>修改</el-link>
                <el-link v-if="scope.row.status == 0" class="list_sty" type="primary"
                  @click="on_assess(scope.row.id)">审批</el-link>
                <el-link v-if="scope.row.status != 0" class="list_sty" type="default" disabled>审批</el-link>
                <!-- <el-link
                        class="list_sty"
                        type="primary"
                        @click="on_particulars(scope.row.id)"
                        >活动台账</el-link
                      > -->

                <!-- <el-link
                        class="list_sty"
                        type="primary"
                        @click="on_yvan = true"
                        >应急预案</el-link
                      > -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新建修改弹窗 -->
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
      <Activitiesredact v-if="dialog_show" ref="Activitiesredact" :refbool.sync="dialog_show"
        @way_tableData="way_tableData" />
    </el-dialog>
    <el-dialog title="发起审批" show-close :visible.sync="auditpop_show" center>
      <auditpop v-if="auditpop_show" ref="auditpop" :refbool.sync="auditpop_show" flowcode_type="flow_activity"
        @way_tableData="way_tableData" />
    </el-dialog>

    <el-dialog title="任务" show-close :visible.sync="renwupop_show" center>
      <renwupop v-if="renwupop_show" ref="renwupop" :refbool.sync="renwupop_show" task_code="activity_recorder"
        @way_tableData="way_tableData" />
    </el-dialog>

    <el-dialog title="审批" :visible.sync="assess_pop" show-close center>
      <el-form label-width="150px" ref="formName" :model="assess_Data">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="活动人群：" prop="add_type" :rules="[{ required: true, message: '请选择事件' }]">
              <el-select v-model="assess_Data.add_type" placeholder="请选择事件对象" @change="assessChangeData">
                <el-option v-for="item in add_type_list" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="活动地点：" prop="place_id" :rules="[{ required: true, message: '请选择地点' }]">
              <el-select v-model="assess_Data.place_id" placeholder="请选择当前地点：" class="form-inp"
                @change="assessChangeData">
                <el-option v-for="item in place_list" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="12">

            <el-form-item label="申报部门：" prop="department_id" :rules="[{ required: true, message: '请选择申报部门' }]">
              <Bepartment class="form-inp" ref="assess_Bepartment" v-model="assess_Data.department_id"
                @changeData="assessChangeData" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务表单：" prop="scheme_id" :rules="[{ required: true, message: '请选择任务表单' }]">
              <el-select class="form-inp" v-model="assess_Data.scheme_id" placeholder="任务表单：" @change="assessChangeData">
                <el-option v-for="item in scheme_list" :key="item.value" :label="item.task_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="block">
            <el-timeline>
              <el-timeline-item v-for="(item, index) in flowList" :key="index" :timestamp="item.activityId"
                placement="top" style="padding-bottom: none;">
                <el-card>

                  <h4>{{ item.activityName }} : {{ item.activityValues }}</h4>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <template slot-scope="scope"></template>
        <el-button @click="on_preserve('formName')" type="primary">审批</el-button>

        <el-button @click="assess_pop = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import BepartmentTree from "../../components/Bepartment_tree";
import auditpop from "../../components/audit_pop";
import renwupop from "../../components/renwu_pop";
import Activitiesredact from "../../components/view_redact/Activities_redact.vue";
import uploadingimg from "../../components/uploading/uploading_img.vue";
import Bepartment from "../../components/Bepartment";

import {
  activityindex,
  activitydelete,
  activitystatusupdate,
  activityExestimateInfo,
  activityEstimateSave,
  activityForcastFlow
} from "../../assets/request/api";
export default {
  name: "thought_education",
  mixins: [table_minin],
  components: {
    BepartmentTree,
    Activitiesredact,
    Bepartment,
    auditpop,
    renwupop,
    uploadingimg,
  },
  data() {
    return {
      title: "",
      dialog_show: false,
      is_extend: true,
      on_yvan: false,
      enable_list: [],
      assess_pop: false,

      assess_Data: {
        id: "",
        add_type: null,
        place_id: null,
        department_id: 0,
        scheme_id: "",
      },
      formLabelAlign: {
        name: "",
        add_type: "",
        place_id: "",
        department_id: "",
        bengin_time: "",
        end_time: "",
        status: "",
      },
      add_type_list: [
        {
          'id': "学生",
          'name': "学生"
        }, {
          'id': "教职工",
          'name': "教职工"
        }
      ],
      place_list: [],
      scheme_list: [],
      drawer_show: false,
      department_name: "",
      flowList: []
    };
  },
  created() {
    this.url = activityindex;
    this.is_enableUrl = activitystatusupdate;
    this.delete_Url = activitydelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {
      this.enable_list = data.enable_list;
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.Activitiesredact.way_show(id);
      });
    },
    on_particulars(id) {
      this.$router.push({
        path: "/security/Activities_particulars",
        query: {
          id: id,
        },
      });
    },
    on_assess(id) {
      this.assess_Data.id = id;
      activityExestimateInfo({ id }).then((res) => {
        let {
          placeList,
          info,
          scheme_list,
        } = res.data;
        let { add_type, department_id, place_id } =
          info;
        this.place_list = placeList;
        this.scheme_list = scheme_list;
        this.assess_pop = true;
        this.assess_Data.scheme_id = scheme_list[0].id;
        this.assess_Data.department_id = department_id;
        this.assess_Data.place_id = place_id;
        this.assess_Data.add_type = add_type;
        this.$nextTick(function () {
          this.$refs.assess_Bepartment.bepartment_List = department_id;
        });
      }).then(() => {
        this.assessChangeData()
      });
    },
    // assessChangeData(){
    //   this.assessChangeData(this.assess_Data)
    // },
    assessChangeData() {
      activityForcastFlow(this.assess_Data).then((res) => {
        let flowList = res.data;
        this.flowList = flowList

      })
    },
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.bengin_time
          );
        },
      };
    },
    //指派保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { assess_Data } = this;
          let From_Data = JSON.parse(JSON.stringify(assess_Data));
          activityEstimateSave(From_Data).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.way_tableData();
              this.assess_pop = false;
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.BM {
  ::v-deep .el-form-item__content {
    display: flex;
  }
}

div.justify {
  text-align: justify;
  width: 69px;
  font-size: 14px;
  color: #606266;
  height: 32px;
  margin-right: 21px;
}

div.justify>span {
  display: inline-block;
  padding-left: 100% !important;
}

.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
